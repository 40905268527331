import {
  NotificationsProvider as RootNotificationsProvider,
  SnackbarOrigin,
  useNotifications,
} from '@lib/use-notifications';
import UI from '@ui-system/ui';
import React from 'react';

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
}

const Close = (key: number) => {
  const { closeNotification } = useNotifications();
  return (
    <UI.Icon
      onClick={() => closeNotification(key)}
      name="close"
      size={24}
      color="white"
    />
  );
};

const ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right',
};
export const NotificationsProvider: React.FC<Props> = ({ children }: Props) => (
  <RootNotificationsProvider
    anchorOrigin={ANCHOR_ORIGIN}
    action={Close}
    autoHideDuration={3000}
  >
    {children}
  </RootNotificationsProvider>
);

export default NotificationsProvider;
