import { IModule } from 'redux-dynamic-modules';

import { EPICS } from './epics';
import { reducer } from './reducer';
import { NAMESPACE, RootState } from './types';

export const getRetrieveDraftModule = (): IModule<RootState> => ({
  id: NAMESPACE,
  reducerMap: {
    [NAMESPACE]: reducer,
  },
  // @ts-ignore
  epics: EPICS,
});
