import HtmlParser from '@html-parser/core';
import { ContainerProps } from '@ui-system/interfaces-container';
import UI from '@ui-system/ui';
import * as React from 'react';

interface Props extends ContainerProps {
  content: string;
  modifiers?: string;
}
export const BaseTextCard: React.FC<Props> = ({
  content,
  modifiers,
  ...rest
}: Props) => {
  const item = HtmlParser(content);
  return (
    <UI.Container bg="background" p={4} {...rest}>
      <UI.Body2 modifiers={modifiers}>{item}</UI.Body2>
    </UI.Container>
  );
};
