import { useHasLoggedUser } from '@authentication/redux-auth-data/hooks';
import {
  AuthenticatedRoutes,
  NavigationRoute,
  NavigationRouter,
  NavigationSwitch,
} from '@lib/navigation';
import ROUTES, { AUTHENTICATED_ROUTES_CONFIG } from '@rec/routes';
import { HistoryListener } from 'ga';
import { useInitializeOnMount } from 'ga/useInitializeOnMount';
import React from 'react';

import { Editor, Main, Version } from './pages';

const Router: React.FC = () => {
  useInitializeOnMount();
  const hasLoggedUser = useHasLoggedUser();
  return (
    <NavigationRouter basename="/">
      <HistoryListener>
        <AuthenticatedRoutes
          hasValidLogin={hasLoggedUser}
          logged={AUTHENTICATED_ROUTES_CONFIG.logged}
          notLogged={AUTHENTICATED_ROUTES_CONFIG.notLogged}
        />
        <NavigationSwitch>
          <NavigationRoute exact path={ROUTES.MAIN} component={Main} />
          <NavigationRoute path={ROUTES.EDITOR} component={Editor} />
          <NavigationRoute path={ROUTES.VERSION} component={Version} />
        </NavigationSwitch>
      </HistoryListener>
    </NavigationRouter>
  );
};
export default Router;
