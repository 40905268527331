import { isTestOrDevEnvironment } from '@lib/config-loader';
import { LogLevel } from '@logger/core';
import { INVALID_OR_MISSING_TOKEN } from '@resource/i18n-shapes';
import {
  HttpError,
  HttpErrorJSON,
  isHttpError,
} from '@service-layer-utils/errors';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';

import { AuthenticationErrorType } from './AuthenticationErrorType';

export class InvalidOrMissingTokenError
  extends HttpError
  implements isHttpError {
  private readonly internalMessage: string;

  private readonly errorType: AuthenticationErrorType;

  constructor(internalMessage: string, errorType: AuthenticationErrorType) {
    super(StatusCodes.UNAUTHORIZED);
    this.internalMessage = internalMessage;
    this.errorType = errorType;
  }

  toJSON(toClient?: boolean): HttpErrorJSON {
    return this.convertToHttpErrorJSON({
      status: this.httpCode,
      type: !toClient
        ? InvalidOrMissingTokenError.name
        : getReasonPhrase(this.httpCode),
      reason: isTestOrDevEnvironment()
        ? this.internalMessage
        : getReasonPhrase(this.httpCode),
      i18n: INVALID_OR_MISSING_TOKEN,
      level: LogLevel.INFO,
    });
  }
}

export const INVALID_TOKEN_ERROR_JSON = new InvalidOrMissingTokenError(
  '',
  AuthenticationErrorType.INVALID_ACCESS_TOKEN,
).toJSON(true);
