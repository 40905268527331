import { useTextSelector } from '@pro-page-components/utils';
import { useMarketingDataState } from '@rec/logic-marketing-data';
import { TextSection } from '@rec/marketing-data/types';
import { useSelectedMarketingCategory } from '@rec/redux-marketing-categories';
import { SectionWrapper } from '@rec/section-wrapper';
import { BaseTextCard } from '@tool-box-components/base-components';
import { CopyToClipboardButton } from '@ui-system/copy-to-clipboard-button';
import UI from '@ui-system/ui';
import map from 'lodash/map';
import * as React from 'react';

interface ItemProps {
  section: TextSection;
}
const Item: React.FC<ItemProps> = ({ section, ...props }: ItemProps) => {
  const selected = useSelectedMarketingCategory();
  const { value, TextSelector, label } = useTextSelector(section.items);
  return (
    <UI.Container direction="column" key={section.key} gap={2} {...props}>
      <UI.Container direction="column">
        <UI.Body2 modifiers="uppercase">{section.label}</UI.Body2>
        <UI.H5 modifiers="bold, uppercase">{selected?.label}</UI.H5>
      </UI.Container>
      <UI.Divider color="primary" />
      <UI.Container direction="column" gap={2}>
        <UI.Container justify="space-between">
          <UI.Container f={8}>
            <UI.H6 modifiers="bold, uppercase, primary">{label}</UI.H6>
          </UI.Container>
          <TextSelector />
        </UI.Container>
        <BaseTextCard content={value} id="CARD" />
        <UI.Container>
          <CopyToClipboardButton value={value} />
        </UI.Container>
      </UI.Container>
    </UI.Container>
  );
};
export const TextsSection: React.FC = () => {
  const data = useMarketingDataState();
  if (!data) return null;
  return (
    <SectionWrapper bg="white" padding>
      {map(data?.textsSection, section => (
        <Item section={section} key={section.key} />
      ))}
    </SectionWrapper>
  );
};
