import { EN, EN_US } from '@i18n/utils';
import { MarketingData } from '@rec/marketing-data';
import {
  useIsPanel,
  useSelectedMarketingCategory,
} from '@rec/redux-marketing-categories';
import { useJSONDataFromStatic } from '@rec/static';
import { useUserInfo } from '@rec/user-info';
import { useCurrentLanguage } from '@resource/redux-language';
import { U } from '@utils/ts';
import React, { createContext, useContext } from 'react';

class AsyncState<D> {
  loading = false;

  error: U.Nullable<Error> = null;

  data: U.Nullable<D> = null;
}

const Context = createContext<AsyncState<MarketingData>>(new AsyncState());

interface MarketingDataContextProviderProps {
  children: React.ReactNode;
}
export function useMarketingData(
  id: U.Nullable<string>,
): {
  data: MarketingData | null;
  loading: boolean;
  error: Error | null;
} {
  const language = useCurrentLanguage();
  const { country } = useUserInfo();
  const isPanel = useIsPanel(id);
  const dataPath = isPanel ? 'REC_PANELS' : 'MARKETING_CATEGORIES';
  const path = `/toolbox-data/${dataPath}/${id}/data-${
    country === 'US' ? EN_US : language
  }.json`;
  const backupPath = `/toolbox-data/${dataPath}/${id}/data-${EN}.json`;
  return useJSONDataFromStatic<MarketingData>({ path, backupPath });
}

function useValue() {
  const selected = useSelectedMarketingCategory();
  return useMarketingData(selected.value);
}
export const MarketingDataContextProvider: React.FC<MarketingDataContextProviderProps> = ({
  children,
}: MarketingDataContextProviderProps) => {
  const value = useValue();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useMarketingDataContext(): AsyncState<MarketingData> {
  return useContext(Context);
}
export function useMarketingDataState(): U.Nullable<MarketingData> {
  const { data } = useMarketingDataContext();
  return data;
}
export function useMarketingDataLoading(): boolean {
  const { loading } = useMarketingDataContext();
  return loading;
}
export function useMarketingDataError(): U.Nullable<Error> {
  const { error } = useMarketingDataContext();
  return error;
}
