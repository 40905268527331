import {
  ReactTutorialStepsProviderType,
  Step,
} from '@lib/react-tutorial-steps';
import {
  TUTORIAL_STEP_FOUR,
  TUTORIAL_STEP_THREE,
  TUTORIAL_STEP_TWO,
} from '@rec/i18n';
import UI from '@ui-system/ui';
import React, { useMemo } from 'react';

import TutorialProvider from './TutorialProvider';

const useYourCompanyTutorialSteps = (): Step[] =>
  useMemo(
    () => [
      {
        content: <UI.Subtitle2 i18n={TUTORIAL_STEP_TWO} />,
        hideCloseButton: true,
        disableBeacon: true,
        placement: 'auto',
        target: '.my-second-step',
      },
      {
        content: <UI.Subtitle2 i18n={TUTORIAL_STEP_THREE} />,
        hideCloseButton: true,
        placement: 'auto',
        target: '.my-third-step',
      },
      {
        content: <UI.Subtitle2 i18n={TUTORIAL_STEP_FOUR} />,
        hideCloseButton: true,
        placement: 'auto',
        target: '.my-fourth-step',
      },
    ],
    [],
  );

export const useYourCompanyTutorial = (): {
  steps: Step[];
  Provider: ReactTutorialStepsProviderType;
} => {
  const steps = useYourCompanyTutorialSteps();
  return useMemo(
    () => ({
      steps,
      Provider: TutorialProvider,
    }),
    [steps],
  );
};

export default useYourCompanyTutorialSteps;
