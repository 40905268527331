import '@lib/config-loader';
import './setupEnvironmentConfigs';
import './fonts/stylesheet.css';
import './configureClassValidatorI18N';

/*
import '@configs/wdyr';
*/
import IconSetProvider from '@lib/icons-set-provider';
import { BUNDLE_NAMES } from '@rec/bundlenames';
import { REC_THEME } from '@rec/theme';
import { COMPONENTS, StylesProvider } from '@rec/ui-system';
import ResourceProvider from '@resource/provider';
import { UiSystemProvider } from '@ui-system/config';
import noop from 'lodash/noop';
import React from 'react';
import { Provider } from 'react-redux';

import { configureLogger } from './configureLogger';
import store from './configureStore';
import Router from './navigation';
import { NotificationsProvider } from './NotificationsProvider';

configureLogger(store).then(noop);

const App: React.FC = () => (
  <UiSystemProvider theme={REC_THEME} components={COMPONENTS}>
    <Provider store={store}>
      <NotificationsProvider>
        <StylesProvider>
          <ResourceProvider bundleNames={BUNDLE_NAMES}>
            <IconSetProvider>
              <Router />
            </IconSetProvider>
          </ResourceProvider>
        </StylesProvider>
      </NotificationsProvider>
    </Provider>
  </UiSystemProvider>
);

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

export default App;
