import { InvalidOrMissingTokenError } from '@authentication/errors';
import { authenticateByEmailAndPasswordActions } from '@authentication/redux-authenticate-by-email-and-password';
import { authenticateByEmailTanActions } from '@authentication/redux-authenticate-by-email-tan';
import { authenticateBySSOTokenActions } from '@authentication/redux-authenticate-by-sso';
import { signInByEmailActions } from '@authentication/redux-sign-in-by-email';
import { ERROR_PATTERN } from '@redux-async-module/actions-utils';
import { Action, Epic } from '@redux-basic-module/interfaces';
import { ofType, ofTypeWithPattern } from '@redux-operators/of-type';
import * as Sentry from '@sentry/react';
import { HttpErrorJSON } from '@service-layer-utils/errors';
import { StatusCodes } from 'http-status-codes';
import { concat, EMPTY, of } from 'rxjs';
import { concatMap, filter, map, switchMap, tap } from 'rxjs/operators';

import { logout, saveAuthData, saveLoginAttemptData } from '../reducer';
import { AuthData } from '../types';

const saveEmailLoginAttemptDataEpic: Epic = action$ =>
  action$.pipe(
    ofType(signInByEmailActions.start.type),
    map(({ payload }: ReturnType<typeof signInByEmailActions.start>) =>
      saveLoginAttemptData(payload),
    ),
  );

const saveTanCodeLoginAttemptDataEpic: Epic = action$ =>
  action$.pipe(
    ofType(authenticateByEmailTanActions.start.type),
    map(({ payload }: ReturnType<typeof authenticateByEmailTanActions.start>) =>
      saveLoginAttemptData(payload),
    ),
  );

const saveSuccessAuthByEmailTanDataEpic: Epic = action$ =>
  action$.pipe(
    ofType(authenticateByEmailTanActions.success.type),
    concatMap(
      ({ payload }: ReturnType<typeof authenticateByEmailTanActions.success>) =>
        concat(
          of(saveAuthData(payload)),
          of(signInByEmailActions.reset()),
          of(authenticateByEmailTanActions.reset()),
        ),
    ),
  );

const saveSuccessAuthByEmailPasswordDataEpic: Epic = action$ =>
  action$.pipe(
    ofType(authenticateByEmailAndPasswordActions.success.type),
    concatMap(
      ({ payload }: ReturnType<typeof authenticateByEmailTanActions.success>) =>
        concat(
          of(saveAuthData(payload)),
          of(authenticateByEmailAndPasswordActions.reset()),
        ),
    ),
  );

const saveSuccessAuthBySSOTokenDataEpic: Epic = action$ =>
  action$.pipe(
    ofType(authenticateBySSOTokenActions.success.type),
    concatMap(
      ({ payload }: ReturnType<typeof authenticateBySSOTokenActions.success>) =>
        concat(
          of(saveAuthData(payload)),
          of(authenticateBySSOTokenActions.reset()),
        ),
    ),
  );

const onErrorNotAuthenticated: Epic = action$ =>
  action$.pipe(
    ofTypeWithPattern(ERROR_PATTERN),
    filter((action: Action<string, any>) => {
      const { payload: error } = action;
      return (
        error != null &&
        error instanceof HttpErrorJSON &&
        error.type === InvalidOrMissingTokenError.name &&
        error.status === StatusCodes.UNAUTHORIZED
      );
    }),
    concatMap(() => of(logout({ sessionExpired: true }))),
  );

const onLoginSetSentryUser: Epic<AuthData> = action$ =>
  action$.pipe(
    ofType(saveAuthData.type),
    tap(action => {
      const { userId } = action.payload;
      Sentry.setUser({ id: userId, username: userId });
    }),
    switchMap(() => EMPTY),
  );

const onLogoutClearSentryUser: Epic = action$ =>
  action$.pipe(
    ofType(logout.type),
    tap(() => {
      Sentry.configureScope(scope => scope.setUser(null));
    }),
    switchMap(() => EMPTY),
  );

export default [
  saveEmailLoginAttemptDataEpic,
  saveTanCodeLoginAttemptDataEpic,
  saveSuccessAuthByEmailTanDataEpic,
  saveSuccessAuthByEmailPasswordDataEpic,
  onErrorNotAuthenticated,
  onLoginSetSentryUser,
  onLogoutClearSentryUser,
  saveSuccessAuthBySSOTokenDataEpic,
];
