import { useCurrentLanguage } from '@resource/redux-language';
import { extractLanguageAndCountryFromLocaleString } from '@resource/utils';
import UI from '@ui-system/ui';
import * as React from 'react';
import ReactPlayer from 'react-player';

function useVideoUrl() {
  const language = useCurrentLanguage();
  return `https://storage.googleapis.com/public-files-rec-propage/tutorial/TUTORIAL_VIDEO_REC_PROPAGE_${
    extractLanguageAndCountryFromLocaleString(language).language
  }.mp4`;
}

interface TutorialVideoProps {
  visible: boolean;
  toggle: () => void;
}
export const TutorialVideo: React.FC<TutorialVideoProps> = ({
  visible,
  toggle,
}: TutorialVideoProps) => {
  const url = useVideoUrl();
  return (
    <UI.Modal visible={visible} toggle={toggle}>
      <UI.Container justify="center" align="center">
        <ReactPlayer url={url} width={720} height={480} controls />
      </UI.Container>
    </UI.Modal>
  );
};
