import {
  AuthenticateByEmailPasswordRequestDTO,
  AuthenticateByEmailTanResultDTO,
} from '@authentication/dtos';
import { State } from '@redux-async-module/interfaces';

export const NAMESPACE = '@auth/authenticate-by-email-and-password';

export type AuthenticateByEmailAndPasswordStartPayload = AuthenticateByEmailPasswordRequestDTO;
export type AuthenticateByEmailAndPasswordSuccessPayload = AuthenticateByEmailTanResultDTO;

export interface RootState {
  [NAMESPACE]: State<
    AuthenticateByEmailAndPasswordStartPayload,
    AuthenticateByEmailAndPasswordSuccessPayload
  >;
}
