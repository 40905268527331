import { deepMergeAll } from '@shared-utils/object';
import { O } from '@utils/ts';
import compact from 'lodash/compact';

import { useDeepCompareMemo } from './useDeepCompareMemo';

export function useMerged<R extends O.Object = O.Object>(
  ...args: O.Object[]
): R {
  const arr = compact(args);
  return useDeepCompareMemo(() => deepMergeAll(arr), [arr]) as R;
}
