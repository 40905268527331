import { IModule } from 'redux-dynamic-modules';

import { reducer } from './reducer';
import { NAMESPACE, RootState } from './types';

export const getUserInfoModule = (): IModule<RootState> => ({
  id: NAMESPACE,
  reducerMap: {
    [NAMESPACE]: reducer,
  },
});
