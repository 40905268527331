import { DynamicModuleLoader } from '@redux-basic-module/module-utils';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { getAuthenticateBySSOTokenModule } from './module';

type Props = {
  children: React.ReactNode;
};

const modules = [getAuthenticateBySSOTokenModule()];
const AuthenticateBySSOTokenModuleLoader: React.FC = ({ children }: Props) => {
  const [render, setRender] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setRender(true), 50);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return render ? (
    <DynamicModuleLoader modules={modules}>{children}</DynamicModuleLoader>
  ) : null;
};
export default AuthenticateBySSOTokenModuleLoader;
