import { CREATE_YOUR_CUSTOMIZED, REC_PROPAGE } from '@rec/i18n';
import { getStaticUrlWithParams } from '@rec/static';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import * as React from 'react';

const STYLE = css`
  position: absolute;
  top: 0;
  margin: auto;
  width: 100%;
  height: 260px;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.4);
`;
export const ToolboxBanner: React.FC = () => (
  <UI.Container direction="column" f={1}>
    <UI.Image
      src={getStaticUrlWithParams('/images/banners/banner_toolbox.jpg')}
      height={260}
      width="100%"
      resizeMode="cover"
    />
    <UI.Container
      style={STYLE}
      direction="column"
      align="center"
      justify="center"
      p={10}
    >
      <UI.H3 i18n={REC_PROPAGE} modifiers="light" />
      <UI.Body1 i18n={CREATE_YOUR_CUSTOMIZED} modifiers="light" />
    </UI.Container>
  </UI.Container>
);
