import { CHOOSE_A_OPTION_BELOW } from '@rec/i18n';
import { SectionWrapper } from '@rec/section-wrapper';
import UI from '@ui-system/ui';
import * as React from 'react';

import { ProPageCard } from './ProPageCard';
import ToolBoxCard from './ToolBoxCard';

const ChooseYourOption: React.FC = () => (
  <SectionWrapper bg="background">
    <UI.Container align="center" direction="column">
      <UI.Container p={4}>
        <UI.H6 i18n={CHOOSE_A_OPTION_BELOW} />
      </UI.Container>
      <UI.Container direction="row" justify="space-between" f={1} w="100%">
        <ProPageCard />
        <ToolBoxCard />
      </UI.Container>
    </UI.Container>
  </SectionWrapper>
);

export default ChooseYourOption;
