import { getAuthenticateByEmailAndPasswordUrl } from '@authentication/shared-routes';
import { RequestType } from '@redux-async-module/interfaces';
import { createModule } from '@redux-async-module/module-utils';
import { selectors as languageSelectors } from '@resource/redux-language/reducer';

import {
  AuthenticateByEmailAndPasswordStartPayload,
  AuthenticateByEmailAndPasswordSuccessPayload,
  NAMESPACE,
  RootState,
} from './types';

const {
  getDynamicModule,
  actions,
  dispatchHooks,
  selectorHooks,
} = createModule<
  RootState,
  AuthenticateByEmailAndPasswordStartPayload,
  AuthenticateByEmailAndPasswordSuccessPayload
>({
  namespace: NAMESPACE,
  actionName: 'AUTHENTICATE_BY_EMAIL_AND_PASSWORD',
  mainEpicConfig: {
    requestOptions: {
      type: RequestType.post,
      getPath: (_, state) =>
        getAuthenticateByEmailAndPasswordUrl(
          languageSelectors.currentLanguage(state),
        ),
    },
  },
});

export const authenticateByEmailAndPasswordActions = actions;

export const {
  useIsLoading: useAuthenticateByEmailAndPasswordIsLoading,
  useError: useAuthenticateByEmailAndPasswordError,
  useSuccess: useAuthenticateByEmailAndPasswordSuccess,
} = selectorHooks;

export const {
  useAsyncStart: useAuthenticateByEmailAndPasswordStart,
  useDismissError: useAuthenticateByEmailAndPasswordDismissError,
} = dispatchHooks;

export const getAuthenticateByEmailAndPasswordModule = getDynamicModule;
