import { Module } from '@redux-basic-module/interfaces';

import epics from './epics';
import reducer from './reducer';
import { NAMESPACE, RootState } from './types';

export function getAuthDataModule(): Module<RootState> {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: reducer,
    },
    epics,
  };
}
