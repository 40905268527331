import {
  MarketingDataContextProvider,
  useMarketingDataError,
  useMarketingDataLoading,
} from '@rec/logic-marketing-data';
import {
  useSelectedMarketingCategory,
  useSelectFirstMarketingCategoryOnMount,
} from '@rec/redux-marketing-categories';
import { SectionWrapper } from '@rec/section-wrapper';
import { REQUEST_ERROR } from '@resource/i18n-shapes';
import {
  DownloadsSection,
  ImagesSection,
  TextsSection,
  VideosSection,
} from '@tool-box-components/sections';
import UI from '@ui-system/ui';
import * as React from 'react';

import ChooseYourOption from './ChooseYourOption';

const Loader: React.FC = () => {
  const loading = useMarketingDataLoading();
  const error = useMarketingDataError();
  if (error) {
    return (
      <UI.Container p={4} bg="error">
        <UI.Body2 i18n={REQUEST_ERROR} modifiers="light" />
      </UI.Container>
    );
  }
  if (!loading) return null;
  return (
    <SectionWrapper bg="white" padding>
      <UI.Container direction="column">
        <UI.Skeleton height={20} width={300} />
        <UI.Skeleton height={40} width={100} />
      </UI.Container>
      <UI.Container m="2, 0">
        <UI.Divider color="primary" />
      </UI.Container>
      <UI.Container direction="column" gap={2}>
        <UI.Container justify="space-between">
          <UI.Container f={8}>
            <UI.Skeleton height={40} width={200} />
          </UI.Container>
        </UI.Container>
        <UI.Skeleton height={300} width="100%" />
      </UI.Container>
    </SectionWrapper>
  );
};
export const ToolboxContent: React.FC = () => {
  const selected = useSelectedMarketingCategory();
  useSelectFirstMarketingCategoryOnMount();
  return selected ? (
    <MarketingDataContextProvider>
      <UI.Container direction="column">
        <UI.Container direction="column">
          <Loader />
          <TextsSection />
          <DownloadsSection />
          <ImagesSection />
          <VideosSection />
        </UI.Container>
      </UI.Container>
    </MarketingDataContextProvider>
  ) : (
    <ChooseYourOption />
  );
};
