import { hashObject } from '@hasher/object-hash';
import { useChunk, useMerged } from '@react-utils/hooks';
import { PANELS_MODELS, SELECT_A_PANEL_MODEL } from '@rec/i18n';
import { MarketingCategory } from '@rec/marketing-data';
import {
  useIsLoadingMarketingCategory,
  useLoadMarketingCategoriesOnMount,
  useMarketingCategoryData,
  usePanelsData,
  useSelectedMarketingCategory,
  useSelectMarketingCategory,
} from '@rec/redux-marketing-categories';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import map from 'lodash/map';
import some from 'lodash/some';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

interface ButtonProps {
  item: MarketingCategory;
  style?: Style;
}

const useStyle = makeStyle<{ selected: boolean }>(({ theme, utils, props }) =>
  props.selected
    ? css`
        width: 100%;
        min-width: 300px;
        ${utils.applyBackgroundColor(theme, 'primary')}
        ${utils.applyColor(theme, 'black')};
        ${utils.applyShadow(theme, 0)};
      `
    : css`
        width: 100%;
        min-width: 300px;
        ${utils.applyShadow(theme, 0)};
      `,
);
const Button: React.FC<ButtonProps> = ({
  item,
  style,
  ...props
}: ButtonProps) => {
  const selectedValue = useSelectedMarketingCategory();
  const selected = selectedValue?.value === item.value;
  const selectCategory = useSelectMarketingCategory();
  const selectedStatus = useStyle({ selected });
  const mergedStyle = useMerged(style as Style, selectedStatus);
  return (
    <UI.Button
      onClick={() => selectCategory({ category: item })}
      key={item.value}
      variant={selected ? 'contained' : 'outlined'}
      style={mergedStyle}
      {...props}
    >
      {item.label}
    </UI.Button>
  );
};

const BUTTON_STYLE = css`
  font-size: 12px;
`;

const DATA = Array.from(['a', 'b', 'c', 'd']);

const Loader: React.FC = props => {
  const chunks = useChunk(DATA, 2);
  return (
    <UI.Container {...props} gap={2}>
      {map(chunks, chunk => (
        <UI.Container key={hashObject(chunk)} direction="column" gap={2}>
          {map(chunk, item => (
            <UI.Skeleton width={250} height={50} key={item} />
          ))}
        </UI.Container>
      ))}
    </UI.Container>
  );
};

const STYLE = css`
  z-index: 1;
`;

const useSelectStyle = makeStyle(
  ({ theme, utils }) => css`
    padding: 0 0 0 10px;
    height: 35px;
    margin: 2px;
    ${utils.applyBackgroundColor(theme, 'transparent')};
  `,
);

const Select = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const style = useSelectStyle();
  const panels = usePanelsData();
  const select = useSelectMarketingCategory();
  const selected = useSelectedMarketingCategory();

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  const IconComponent: React.FC = () => (
    <UI.Container
      bg="primary"
      align="center"
      justify="center"
      p={1}
      style={STYLE}
      onClick={handleOpen}
    >
      <UI.Icon name="down" size={24} color="black" />
    </UI.Container>
  );

  const handleSelectPanel = useCallback(
    value => {
      select({ category: value });
      handleClose();
    },
    [handleClose, select],
  );

  const value = useMemo(() => {
    const hasInPanels = some(panels, item => item.value === selected?.value);
    if (hasInPanels) return selected;
    return '';
  }, [panels, selected]);
  if (!panels) return null;

  return (
    <UI.Container w="100%" m="2, 0">
      <UI.Container f={1}>
        <UI.Container f={1} align="center">
          <UI.Body2 i18n={SELECT_A_PANEL_MODEL} modifiers="uppercase" />
        </UI.Container>
        <UI.Container f={2}>
          <UI.Form.Select
            options={panels}
            placeholderI18n={PANELS_MODELS}
            modifiers="uppercase"
            // @ts-ignore
            value={value}
            // @ts-ignore
            onChange={handleSelectPanel}
            IconComponent={IconComponent}
            open={isOpen}
            style={style}
            disableUnderline
            onOpen={handleOpen}
          />
        </UI.Container>
      </UI.Container>
    </UI.Container>
  );
};
export const MarketingCategories: React.FC = props => {
  useLoadMarketingCategoriesOnMount();
  const data = useMarketingCategoryData();
  const chunks = useChunk(data, 2);
  const isLoadingMarketingCategory = useIsLoadingMarketingCategory();

  if (isLoadingMarketingCategory) return <Loader />;
  return (
    <UI.Container direction="column" {...props}>
      <UI.Container gap={2}>
        {map(chunks, chunk => (
          <UI.Container
            key={hashObject(chunk)}
            direction="column"
            gap={2}
            f={1}
          >
            {map(chunk, item => (
              <Button item={item} key={item.value} style={BUTTON_STYLE} />
            ))}
          </UI.Container>
        ))}
      </UI.Container>
      <Select />
    </UI.Container>
  );
};
