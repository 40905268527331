import { containsAny } from '@lib/utils-string';
import { PdfThumbnail } from '@pdf/core';
import { useMarketingDataState } from '@rec/logic-marketing-data';
import { DownloadSectionItem } from '@rec/marketing-data/types';
import { useSelectedMarketingCategory } from '@rec/redux-marketing-categories';
import { SectionWrapper } from '@rec/section-wrapper';
import { getStaticUrl, getStaticUrlWithParams } from '@rec/static';
import { css } from '@ui-system/css';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import { openUrl } from 'browser';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import * as React from 'react';
import { useMemo } from 'react';

const useStyle = makeStyle(
  ({ theme }) => css`
    border: 1px solid ${theme.colors[theme.mode].gray.A300};
  `,
);

interface ItemProps {
  item: DownloadSectionItem;
}

const Item: React.FC<ItemProps> = ({ item, ...props }: ItemProps) => {
  const style = useStyle();
  const previewUrl = useMemo<string>(
    () =>
      `${getStaticUrl()}${get(
        find(item.urls, urlItem =>
          containsAny(urlItem.link, '.jpg', '.png', '.jpeg'),
        ),
        'link',
        '',
      )}`,
    [item.urls],
  );
  const pdfThumbUrl = useMemo<string | null>(() => {
    const path = get(
      find(item.urls, urlItem =>
        containsAny(urlItem.link, '.jpg', '.png', '.jpeg'),
      ),
      'link',
      null,
    );
    if (path) return path;
    return null;
  }, [item.urls]);
  return (
    <UI.Container
      direction="column"
      bg="white"
      justify="space-between"
      shadow={1}
      w={250}
      {...props}
    >
      <UI.Container style={style} p={6} justify="center">
        {pdfThumbUrl ? (
          <PdfThumbnail
            url={getStaticUrlWithParams(pdfThumbUrl)}
            height={120}
            width={120}
            resizeMode="contain"
          />
        ) : (
          <UI.Image
            src={previewUrl}
            height={90}
            width={90}
            resizeMode="cover"
          />
        )}
      </UI.Container>
      <UI.Container p="4, 3">
        <UI.Body2>{item.label}</UI.Body2>
      </UI.Container>
      <UI.Container p="3">
        {map(item.urls, urlItem => {
          if (containsAny(urlItem.label, 'Thumbnail')) {
            return null;
          }
          return (
            <UI.Container key={urlItem.link} justify="center" f={1}>
              <UI.Button
                onClick={() => openUrl(getStaticUrlWithParams(urlItem.link))}
                accessoryLeft="download"
                variant="outlined"
                iconSize={25}
                iconColor="primary"
              >
                {urlItem.label as string}
              </UI.Button>
            </UI.Container>
          );
        })}
      </UI.Container>
    </UI.Container>
  );
};
export const DownloadsSection: React.FC = () => {
  const selected = useSelectedMarketingCategory();
  const data = useMarketingDataState();
  if (!data) return null;
  return (
    <SectionWrapper bg="background" padding>
      {map(data.downloadsSection, section => (
        <UI.Container direction="column" key={section.key} gap={2} f={1}>
          <UI.Container direction="column">
            <UI.Body2 modifiers="uppercase">{section.label}</UI.Body2>
            <UI.H5 modifiers="bold, uppercase">{selected?.label}</UI.H5>
          </UI.Container>
          <UI.Divider color="primary" />
          <UI.Container gap={2}>
            {map(section.items, item => (
              <Item key={item.key} item={item} />
            ))}
          </UI.Container>
        </UI.Container>
      ))}
    </SectionWrapper>
  );
};
