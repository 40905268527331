import { Action } from 'redux';
import { BATCH, BatchAction } from 'redux-batched-actions';
import { EMPTY, from, of, OperatorFunction } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { isType, isTypeLike } from './utils';

export function ofTypeWithPattern<
  // All possible actions your app can dispatch
  Input extends Action | BatchAction,
  // The types you want to filter for
  Type extends Input['type'],
  // The resulting actions that match the above types
  Output extends Input = Extract<Input, Action<Type>>
>(pattern: string): OperatorFunction<Input, Output> {
  return mergeMap(action => {
    if (isTypeLike(action, pattern)) {
      return of(<Output>action);
    }
    if (isType(action, BATCH)) {
      const batchAction: BatchAction = <BatchAction>action;
      return from(
        <Output[]>(
          batchAction.payload.filter(childAction =>
            isTypeLike(childAction, pattern),
          )
        ),
      );
    }

    return EMPTY;
  });
}
