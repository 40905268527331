import 'reflect-metadata';

import { getEnvironment, isTestOrDevEnvironment } from '@lib/config-loader';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { unregister } from './serviceWorkers';

Sentry.init({
  dsn:
    'https://33e8c30b0ca04e32a221e4f04498cc73@o507712.ingest.sentry.io/5599408',
  autoSessionTracking: true,
  integrations(integrations) {
    integrations.push(
      new Integrations.BrowserTracing({
        // shouldCreateSpanForRequest: url =>
        //   // Do not create spans for outgoing requests to a `/health/` endpoint
        //   !url.match(/\/log\/?$/),
      }),
    );
    return integrations.filter(integration => integration.name !== 'Console');
  },
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: !isTestOrDevEnvironment(),
  environment: getEnvironment(),
});

unregister();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
