import { useMarketingDataState } from '@rec/logic-marketing-data';
import { ImageSectionItem } from '@rec/marketing-data/types';
import { useSelectedMarketingCategory } from '@rec/redux-marketing-categories';
import { SectionWrapper } from '@rec/section-wrapper';
import { getStaticUrl } from '@rec/static';
import { DOWNLOAD } from '@resource/i18n-shapes';
import { css } from '@ui-system/css';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import { openUrl } from 'browser';
import get from 'lodash/get';
import map from 'lodash/map';
import * as React from 'react';
import { useMemo } from 'react';

const CARD_ITEM_STYLE = {
  flex: 1,
  flexWrap: 'wrap',
};

const useStyle = makeStyle(
  ({ theme }) => css`
    border: 1px solid ${theme.colors[theme.mode].gray.A300};
  `,
);

interface ItemProps {
  item: ImageSectionItem;
}

const Item: React.FC<ItemProps> = ({ item }: ItemProps) => {
  const style = useStyle();
  const url = useMemo<string>(
    () => `${getStaticUrl()}${get(item, 'url', '')}`,
    [item],
  );
  return (
    <UI.Container direction="column" bg="white" shadow={1} m="2, 1">
      <UI.Container style={style} justify="center">
        <UI.Image src={url} height={200} width="auto" />
      </UI.Container>
      <UI.Container p="4, 3">
        <UI.Body2>{item.label}</UI.Body2>
      </UI.Container>
      <UI.Container p="3">
        <UI.Container justify="center" f={1}>
          <UI.Button
            onClick={() => openUrl(url)}
            accessoryLeft="download"
            variant="outlined"
            iconSize={25}
            iconColor="primary"
            i18n={DOWNLOAD}
          />
        </UI.Container>
      </UI.Container>
    </UI.Container>
  );
};
export const ImagesSection: React.FC = () => {
  const selected = useSelectedMarketingCategory();
  const data = useMarketingDataState();
  if (!data) return null;
  return (
    <SectionWrapper bg="background" padding>
      {map(data.imagesSection, section => (
        <UI.Container direction="column" key={section.key} gap={2}>
          <UI.Container direction="column">
            <UI.Body2 modifiers="bold, uppercase">{section.label}</UI.Body2>
            <UI.H5 modifiers="bold, uppercase">{selected?.label}</UI.H5>
          </UI.Container>
          <UI.Divider color="primary" />
          <UI.Container style={CARD_ITEM_STYLE} gap={2}>
            {map(section.items, item => (
              <Item key={item.key} item={item} />
            ))}
          </UI.Container>
        </UI.Container>
      ))}
    </SectionWrapper>
  );
};
