import { useHasAValidLogin } from '@authentication/redux-auth-data/hooks';
import { useNavigateToMain } from '@rec/routes/useNavigateToHome';
import { ToolboxHeader } from '@tool-box-components/blocks';
import UI from '@ui-system/ui';
import { openUrl } from 'browser';
import { delay } from 'lodash';
import * as React from 'react';

import pkg from '../../../package.json';

const ENV =
  process.env.NODE_ENV === 'production' ? '' : `@${process.env.NODE_ENV}`;

export const Version: React.FC = () => {
  function logout() {
    localStorage.clear();
    delay(() => openUrl(window.location.href, '_self'), 500);
  }
  const hasAValidLogin = useHasAValidLogin();
  const navigateToMain = useNavigateToMain();
  return (
    <UI.Page>
      <UI.Page.Header>
        <ToolboxHeader />
      </UI.Page.Header>
      <UI.Page.Content>
        <UI.Container align="center" justify="center" p={10}>
          <UI.Typography>
            {pkg.version}
            {ENV}
          </UI.Typography>
        </UI.Container>
        <UI.Container align="center" justify="center">
          {hasAValidLogin ? (
            <UI.Button onClick={logout}>LOGOUT</UI.Button>
          ) : (
            <UI.Button onClick={() => navigateToMain()}>HOME</UI.Button>
          )}
        </UI.Container>
      </UI.Page.Content>
    </UI.Page>
  );
};
