import { CLOSE, SKIP_TUTORIAL } from '@resource/i18n-shapes';
import UI from '@ui-system/ui';
import React, { useState } from 'react';
import Joyride, { Props, Step } from 'react-joyride';

import {
  ReactTutorialStepsContext,
  useContextValue,
  useStopTutorial,
} from './context';

export * from './context';
export * from 'react-joyride';

export interface ReactTutorialStepsProviderProps extends Props {
  children: React.ReactNode | React.ReactNodeArray;
  steps: Step[];
}

export type ReactTutorialStepsProviderType = React.FC<ReactTutorialStepsProviderProps>;

interface JoyrideWrapperProps extends Props {
  run: boolean;
}

const JoyrideWrapper: React.FC<JoyrideWrapperProps> = ({
  run,
  locale,
  ...props
}: JoyrideWrapperProps) => {
  const stopTutorial = useStopTutorial();
  const [newLocales] = useState(() => ({
    skip: <UI.ButtonText i18n={SKIP_TUTORIAL} onClick={stopTutorial} />,
    last: <UI.Button i18n={CLOSE} onClick={stopTutorial} />,
    ...locale,
  }));

  return <Joyride locale={newLocales} run={run} {...props} />;
};

export const ReactTutorialStepsProvider: React.FC<ReactTutorialStepsProviderProps> = ({
  children,
  ...props
}: ReactTutorialStepsProviderProps) => {
  const value = useContextValue();

  return (
    <ReactTutorialStepsContext.Provider value={value}>
      <JoyrideWrapper run={value.run} {...props} />
      {children}
    </ReactTutorialStepsContext.Provider>
  );
};
