import {
  CREATE_A_REC_PRO_PAGE_CALL_TO_ACTION,
  CREATE_NOW,
  REC_PROPAGE,
} from '@rec/i18n';
import { getStaticUrlWithParams } from '@rec/static';
import { useOpenEditorPage } from '@rec/user-info';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import * as React from 'react';

const STYLE = css`
  position: absolute;
  width: 100%;
  flex: 1;
`;

const BUTTON_STYLE = css`
  min-width: 300px;
`;

export const ToolboxFooter: React.FC = () => {
  const openEditorPage = useOpenEditorPage();
  return (
    <UI.Container f={1}>
      <UI.Image
        src={getStaticUrlWithParams('/images/banners/banner_toolbox.jpg')}
        height="auto"
        width="100%"
        resizeMode="cover"
      />
      <UI.Container
        m="5, 0"
        style={STYLE}
        direction="column"
        align="center"
        justify="center"
        p={10}
        gap={4}
      >
        <UI.H3 i18n={REC_PROPAGE} modifiers="light" />
        <UI.H5 i18n={CREATE_A_REC_PRO_PAGE_CALL_TO_ACTION} modifiers="light" />
        <UI.Button
          i18n={CREATE_NOW}
          onClick={openEditorPage}
          style={BUTTON_STYLE}
        />
      </UI.Container>
    </UI.Container>
  );
};

export default ToolboxFooter;
