import { getAuthDataModule } from '@authentication/redux-auth-data';
import { getAuthenticateByEmailTanModule } from '@authentication/redux-authenticate-by-email-tan';
import { getAuthenticateBySSOTokenModule } from '@authentication/redux-authenticate-by-sso';
import { getSignInByEmailModule } from '@authentication/redux-sign-in-by-email';
import { getFastFormModule } from '@fast-form/module';
// import { HEALTH_CHECK_MODULE } from '@health-check/logic-check-health';
import createDynamicStore from '@lib/redux-dynamic-store';
import { getMarketingCategoriesModule } from '@rec/redux-marketing-categories';
import { getCreateUpdateDraftModule } from '@rec/redux-page-creator-draft';
import { getCreateUpdateLiveModule } from '@rec/redux-page-creator-live';
import { getRetrieveDraftModule } from '@rec/redux-retrieve-draft';
import { getUserInfoModule } from '@rec/user-info';
import { getPersistStoreModule } from '@redux-persisted/module';
import { getBundleModule } from '@resource/redux-bundle';
import { getLanguageModule } from '@resource/redux-language';

const DEFAULT_LANGUAGE = 'en';

const BUNDLE_MODULE = getBundleModule();
const LANGUAGE_MODULE = getLanguageModule(DEFAULT_LANGUAGE);
const AUTH_DATA = getAuthDataModule();
const USER_INFO = getUserInfoModule();

export default createDynamicStore([
  getFastFormModule(),
  BUNDLE_MODULE,
  LANGUAGE_MODULE,
  // HEALTH_CHECK_MODULE,
  USER_INFO,
  getMarketingCategoriesModule(),
  AUTH_DATA,
  getSignInByEmailModule(),
  getAuthenticateByEmailTanModule(),
  getCreateUpdateDraftModule(),
  getRetrieveDraftModule(),
  getCreateUpdateLiveModule(),
  getAuthenticateBySSOTokenModule(),
  getPersistStoreModule(
    [BUNDLE_MODULE.id, LANGUAGE_MODULE.id, AUTH_DATA.id, USER_INFO.id],
    'rec-pro-page-editor',
  ),
]);
