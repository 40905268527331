import { L, U } from '@utils/ts';
import chunk from 'lodash/chunk';

import { useDeepCompareMemo } from './useDeepCompareMemo';

export function useChunk<A extends L.List = L.List>(
  arr: U.Nullable<A>,
  n: number,
): A[] {
  return useDeepCompareMemo<A[]>(() => (chunk(arr, n) as unknown) as A[], [
    arr,
    n,
  ]);
}
