import {
  OPEN_REC_TOOLBOX,
  WEB_TOOLBOX,
  WEB_TOOLBOX_DESCRIPTION,
} from '@rec/i18n';
import { useSelectDefaultCategory } from '@rec/redux-marketing-categories';
import { getStaticUrlWithParams } from '@rec/static';
import UI from '@ui-system/ui';
import * as React from 'react';

const ToolBoxCard: React.FC = (): JSX.Element => {
  const select = useSelectDefaultCategory();
  return (
    <UI.Container
      justify="space-between"
      direction="column"
      f={2}
      gap={2}
      p="0,8"
    >
      <UI.Container>
        <UI.Image
          src={getStaticUrlWithParams('/images/logos/web_toolbox.jpg')}
          height="147px"
          width="380px"
        />
      </UI.Container>
      <UI.Container direction="column" gap={2}>
        <UI.H6 i18n={WEB_TOOLBOX} />
        <UI.Subtitle2 i18n={WEB_TOOLBOX_DESCRIPTION} />
        <UI.Container>
          <UI.Button i18n={OPEN_REC_TOOLBOX} onClick={select} />
        </UI.Container>
      </UI.Container>
    </UI.Container>
  );
};

export default ToolBoxCard;
