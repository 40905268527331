export enum AuthenticationErrorType {
  INVALID_TAN = 'invalid-tan',
  EMAIL_NOT_FOUND = 'email-not-found',
  EMAIL_NOT_AUTHORIZED = 'email-not-authorized',
  ROLES_NOT_ACCEPTABLE = 'roles-not-acceptable',
  PASSWORD_NOT_AVAILABLE = 'password-not-available',
  INVALID_PASSWORD = 'invalid-password',
  INTERNAL = 'internal',
  INVALID_ACCESS_TOKEN = 'invalid-access-token',
}
