import { useSelectedMarketingCategory } from '@rec/redux-marketing-categories';
import { useSetUserInfoOnMountForToolBox } from '@rec/user-info';
import {
  ToolboxContent,
  ToolboxFooter,
  ToolboxHeader,
} from '@tool-box-components/blocks';
import UI from '@ui-system/ui';
import * as React from 'react';

const Footer = () => {
  const selected = useSelectedMarketingCategory();
  if (!selected) return null;
  return (
    <UI.Page.Footer>
      <ToolboxFooter />
    </UI.Page.Footer>
  );
};

export const Main: React.FC = () => {
  useSetUserInfoOnMountForToolBox();
  return (
    <UI.Page>
      <UI.Page.Header>
        <ToolboxHeader />
      </UI.Page.Header>
      <UI.Page.Content>
        <ToolboxContent />
      </UI.Page.Content>
      <Footer />
    </UI.Page>
  );
};
