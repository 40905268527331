import { useDeepCompareEffect } from '@react-utils/hooks';
import includes from 'lodash/includes';
import * as React from 'react';

import { useLocation, useNavigateTo } from '../hooks';

interface AuthenticatedRoutesProps {
  logged: {
    allowedRoutes: string[];
    baseRoute: string;
  };
  notLogged: {
    allowedRoutes: string[];
    baseRoute: string;
  };
  hasValidLogin: boolean;
}

const AuthenticatedRoutes: React.FC<AuthenticatedRoutesProps> = ({
  logged,
  notLogged,
  hasValidLogin,
}: AuthenticatedRoutesProps) => {
  const navigateTo = useNavigateTo();
  const location = useLocation();
  useDeepCompareEffect(() => {
    if (hasValidLogin && !includes(logged.allowedRoutes, location.pathname)) {
      navigateTo(logged.baseRoute);
    } else if (
      !hasValidLogin &&
      !includes(notLogged.allowedRoutes, location.pathname)
    ) {
      navigateTo(notLogged.baseRoute);
    }
  }, [hasValidLogin, notLogged, logged, location, navigateTo]);
  return null;
};

export default AuthenticatedRoutes;
