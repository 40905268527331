import { useAuthenticatedHeaders } from '@authentication/redux-auth-data/utils';
import {
  getPageLiveIsAvailableUrl,
  getPageLiveIsOwnedUrl,
  getUserPageUrlsUrl,
} from '@page-creator/shared-routes';
import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useAsync, UseAsyncReturn } from 'react-async-hook';

export async function checkUrlPathIsAvailable(
  urlPath: string,
): Promise<AxiosResponse<boolean>> {
  return axios.get(getPageLiveIsAvailableUrl(urlPath));
}

export async function checkUrlPathIsOwned(
  urlPath: string,
  headers: Record<string, any>,
): Promise<AxiosResponse<boolean>> {
  return axios.get(getPageLiveIsOwnedUrl(urlPath), { headers });
}

export async function getUserPageUrls(
  headers: Record<string, any>,
): Promise<string[]> {
  const response = await axios.get(getUserPageUrlsUrl(), { headers });
  return response.data;
}

export function useCheckUrlPathIsOwned(): (
  urlPath: string,
) => Promise<boolean> {
  const headers = useAuthenticatedHeaders();
  return useCallback(
    async (urlPath: string) => {
      try {
        const response = await checkUrlPathIsOwned(urlPath, headers);
        return response.data;
      } catch (e) {
        return false;
      }
    },
    [headers],
  );
}

export function useUserPageUrls(): UseAsyncReturn<string[]> {
  const headers = useAuthenticatedHeaders();
  return useAsync(getUserPageUrls, [headers]);
}
