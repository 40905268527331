import { hashObject } from '@hasher/object-hash';
import { useChunk } from '@react-utils/hooks';
import { useMarketingDataState } from '@rec/logic-marketing-data';
import { VideoSectionItem } from '@rec/marketing-data';
import { useSelectedMarketingCategory } from '@rec/redux-marketing-categories';
import { SectionWrapper } from '@rec/section-wrapper';
import { COPY_LINK } from '@resource/i18n-shapes';
import { CopyToClipboardButton } from '@ui-system/copy-to-clipboard-button';
import UI from '@ui-system/ui';
import map from 'lodash/map';
import size from 'lodash/size';
import * as React from 'react';
import ReactPlayer from 'react-player';

interface ItemsProps {
  items: VideoSectionItem[];
}

const Items: React.FC<ItemsProps> = ({ items, ...rest }: ItemsProps) => {
  const chunks = useChunk(items, 2);
  return (
    <UI.Container direction="column" gap={2} {...rest}>
      {map(chunks, (chunk, i) => (
        <UI.Container gap={2} key={hashObject(chunk)}>
          <>
            {map(chunk, item => (
              <UI.Container direction="column" key={item.key} gap={2} f={1}>
                {map(item.urls, urlItem => (
                  <UI.Container
                    key={urlItem.link}
                    direction="column"
                    gap={2}
                    shadow={2}
                    bg="white"
                    m="0, 1"
                  >
                    <ReactPlayer url={urlItem.link} width="100%" />
                    <UI.Container justify="space-between" p={2} align="center">
                      <UI.Caption modifiers="uppercase">
                        {item.label}
                      </UI.Caption>
                      <CopyToClipboardButton
                        value={urlItem.link}
                        iconName="link"
                        i18n={COPY_LINK}
                      />
                    </UI.Container>
                  </UI.Container>
                ))}
              </UI.Container>
            ))}
            {size(chunk) % 2 !== 0 && i === size(chunk) && (
              <UI.Container f={1} />
            )}
            {size(items) === 1 && <UI.Container f={1} />}
          </>
        </UI.Container>
      ))}
    </UI.Container>
  );
};
export const VideosSection: React.FC = () => {
  const selected = useSelectedMarketingCategory();
  const data = useMarketingDataState();
  if (!data) return null;
  return (
    <SectionWrapper bg="background" padding>
      {map(data?.videosSection, section => (
        <UI.Container direction="column" key={section.key} gap={2} m="4, 0">
          <UI.Container direction="column">
            <UI.Body2 modifiers="uppercase">{section.label}</UI.Body2>
            <UI.H5 modifiers="bold, uppercase">{selected?.label}</UI.H5>
          </UI.Container>
          <UI.Divider color="primary" />
          <Items items={section.items} />
        </UI.Container>
      ))}
    </SectionWrapper>
  );
};
