import { CONTENT_TO_DRIVE_SALES, REC_TOOLBOX } from '@rec/i18n';
import { useSelectedMarketingCategory } from '@rec/redux-marketing-categories';
import { SectionWrapper } from '@rec/section-wrapper';
import { MarketingCategories } from '@tool-box-components/marketing-categories';
import UI from '@ui-system/ui';
import * as React from 'react';

import { ToolboxBanner } from './Banner';
import { ProPageCardSmall } from './ProPageCard';

const Banner = () => <ToolboxBanner />;
export const ToolboxHeader: React.FC = () => {
  const selected = useSelectedMarketingCategory();
  return selected ? (
    <SectionWrapper bg="background">
      <UI.Container gap={2} align="center" f={1} justify="space-between">
        <UI.Container direction="column" f={7}>
          <UI.Container gap={2} align="baseline">
            <UI.H5 i18n={REC_TOOLBOX} />
            <UI.Subtitle2 i18n={CONTENT_TO_DRIVE_SALES} modifiers="grayA400" />
          </UI.Container>
          <MarketingCategories />
        </UI.Container>
        <UI.Container f={3}>
          <ProPageCardSmall />
        </UI.Container>
      </UI.Container>
    </SectionWrapper>
  ) : (
    <Banner />
  );
};
