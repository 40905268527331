import {
  Locale,
  ReactTutorialStepsProvider,
  ReactTutorialStepsProviderProps,
  ReactTutorialStepsProviderType,
  Styles,
} from '@lib/react-tutorial-steps';
import { BACK, NEXT } from '@resource/i18n-shapes';
import { useTheme } from '@ui-system/theme';
import UI from '@ui-system/ui';
import React, { useMemo } from 'react';

const useLocales = (): Locale =>
  useMemo(
    () => ({
      next: <UI.Button i18n={NEXT} onClick={() => null} />,
      back: <UI.Button i18n={BACK} modifiers="dark" onClick={() => null} />,
    }),
    [],
  );

const useStyles = (): Styles => {
  const theme = useTheme();
  return useMemo(
    () => ({
      options: {
        arrowColor: theme.colors[theme.mode].background,
        backgroundColor: theme.colors[theme.mode].background,
        primaryColor: 'transparent',
        zIndex: 1000,
      },
      beaconInner: {
        backgroundColor: theme.colors[theme.mode].primary.main,
      },
      beaconOuter: {
        backgroundColor: theme.colors[theme.mode].primary.main,
      },
    }),
    [theme.colors, theme.mode],
  );
};

const FLOATER_OPTIONS = {
  disableAnimation: true,
};

export const TutorialProvider: ReactTutorialStepsProviderType = ({
  children,
  steps,
}: ReactTutorialStepsProviderProps) => {
  const LOCALE = useLocales();
  const STYLES = useStyles();
  return (
    <ReactTutorialStepsProvider
      steps={steps}
      continuous
      showSkipButton
      disableOverlayClose
      disableCloseOnEsc
      scrollToFirstStep
      floaterProps={FLOATER_OPTIONS}
      styles={STYLES}
      locale={LOCALE}
    >
      {children}
    </ReactTutorialStepsProvider>
  );
};

export default TutorialProvider;
