import { TutorialVideo } from '@page-creator/context/TutorialVideo';
import { useIsOpen } from '@react-utils/hooks';
import {
  CREATE_A_REC_PRO_PAGE_CALL_TO_ACTION,
  OPEN_PRO_PAGE_EDITOR,
  PROPAGE_DESCRIPTION,
  REC_PROPAGE,
  WATCH_VIDEO,
} from '@rec/i18n';
import { getStaticUrlWithParams } from '@rec/static';
import { useOpenEditorPage } from '@rec/user-info';
import { CREATE_NOW } from '@resource/i18n-shapes';
import UI from '@ui-system/ui';
import * as React from 'react';

export const ProPageCard: React.FC = () => {
  const openEditorPage = useOpenEditorPage();
  const { isOpen, toggle } = useIsOpen();
  const showTutoraialVideo = false;
  return (
    <UI.Container justify="space-between" direction="column" f={2} p="0,8">
      <TutorialVideo toggle={toggle} visible={isOpen} />
      <UI.Container>
        <UI.Image
          src={getStaticUrlWithParams('/images/logos/rec_propage.jpg')}
          height="147px"
          width="380px"
        />
      </UI.Container>
      <UI.H6 i18n={REC_PROPAGE} modifiers="bold" />
      <UI.Subtitle2 i18n={PROPAGE_DESCRIPTION} />
      <UI.Container direction="column">
        <UI.Container direction="row">
          {showTutoraialVideo && (
            <UI.Button i18n={WATCH_VIDEO} onClick={toggle} variant="outlined" />
          )}
          <UI.Container>
            <UI.Button i18n={CREATE_NOW} onClick={openEditorPage} />
          </UI.Container>
        </UI.Container>
      </UI.Container>
    </UI.Container>
  );
};

export const ProPageCardSmall: React.FC = () => {
  const openEditorPage = useOpenEditorPage();
  return (
    <UI.Container
      bg="black"
      p="4, 6"
      justify="center"
      direction="column"
      gap={1}
      f={1}
    >
      <UI.Container f={1}>
        <UI.Image
          src={getStaticUrlWithParams('/images/logos/logo_pro_page.png')}
          height={50}
          width={150}
        />
      </UI.Container>
      <UI.Container f={1} direction="column" gap={2} justify="center">
        <UI.Container>
          <UI.Body2
            i18n={CREATE_A_REC_PRO_PAGE_CALL_TO_ACTION}
            modifiers="light"
          />
        </UI.Container>
        <UI.Container direction="column" w="225px">
          <UI.Button onClick={openEditorPage} i18n={OPEN_PRO_PAGE_EDITOR} />
        </UI.Container>
      </UI.Container>
    </UI.Container>
  );
};
