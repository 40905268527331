import {
  checkUrlPathIsAvailable,
  useCheckUrlPathIsOwned,
} from '@rec/logic-live-is-available';
import { useCreateUpdateDraftData } from '@rec/redux-page-creator-draft';
import {
  PublishMode,
  useCreateUpdateLive,
  useSetPublishMode,
} from '@rec/redux-page-creator-live';
import { useCallback, useState } from 'react';

import { Form } from './Form';

interface UsePublishFlowReturn {
  isCheckingUrl: boolean;
  isUrlInUse: boolean;
  error: Error | null;
  onPublish: (data: Form) => void;
}

export function usePublishFlow(): UsePublishFlowReturn {
  const [isCheckingUrl, setIsCheckingUrl] = useState(false);
  const [isUrlInUse, setIsUrlInUse] = useState(false);
  const [error, setError] = useState(null);
  const createUpdateLive = useCreateUpdateLive();
  const lastDraft = useCreateUpdateDraftData();
  const setPublishMode = useSetPublishMode();
  const checkUrlPathIsOwned = useCheckUrlPathIsOwned();
  const onPublish = useCallback(
    async (data: Form) => {
      try {
        setIsCheckingUrl(true);
        setIsUrlInUse(false);
        setError(null);
        const response = await checkUrlPathIsAvailable(data.path);
        const isOwned = await checkUrlPathIsOwned(data.path);
        if (response.data || isOwned) {
          createUpdateLive({
            urlPath: data.path,
            contents: lastDraft?.contents,
            deleteOtherOwnedPages: true,
          });
          setPublishMode(PublishMode.SUCCEEDED);
        } else {
          setIsUrlInUse(true);
        }
        setIsCheckingUrl(false);
      } catch (e) {
        setError(e);
        setIsCheckingUrl(false);
      }
    },
    [
      checkUrlPathIsOwned,
      createUpdateLive,
      lastDraft?.contents,
      setPublishMode,
    ],
  );
  return {
    isCheckingUrl,
    isUrlInUse,
    error,
    onPublish,
  };
}
