import { Step } from '@lib/react-tutorial-steps';
import { TUTORIAL_STEP_FIVE, TUTORIAL_STEP_ONE } from '@rec/i18n';
import UI from '@ui-system/ui';
import React, { useMemo } from 'react';

const useEditorTutorialSteps = (): Step[] =>
  useMemo(
    () => [
      {
        content: <UI.Subtitle2 i18n={TUTORIAL_STEP_ONE} />,
        spotlightPadding: 0,
        placementBeacon: 'right',
        placement: 'right',
        target: '.my-first-step',
        disableBeacon: true,
        hideCloseButton: true,
      },
      {
        content: <UI.Subtitle2 i18n={TUTORIAL_STEP_FIVE} />,
        hideCloseButton: true,
        disableBeacon: true,
        placement: 'auto',
        target: '.my-fifth-step',
      },
    ],
    [],
  );

export default useEditorTutorialSteps;
